<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                {{year}} © Admin.
            </div>

        </div>
    </div>
</footer>
