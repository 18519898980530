// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: 'firebase',
  firebaseConfig: {
    apiKey: 'AIzaSyAwvl4tJo4K6qibiKiaGROfXTaClN9wv28',
    authDomain: 'meddrdc-dc37b.firebaseapp.com',
    databaseURL: '',
    projectId: 'meddrdc-dc37b',
    storageBucket: 'meddrdc-dc37b.appspot.com',
    messagingSenderId: '26204008483',
    appId: ':26204008483:web:e075988de163ce45a50196',
    measurementId: ''
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
