import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [

  {
    id: 1,
    label: 'Dashboard',
    icon: 'bx-home-circle',
    link: '/dashboard',
  },
  {
    id: 2,
    label: 'Gestion',
    icon: 'bx-calendar',
    subItems: [
      {
        id: 2,
        label: 'Evenements',
        link: '/gestion/evenement',
        parentId: 2
      },
      {
        id: 3,
        label: 'Etablissements',
        link: '/gestion/etablissement',
        parentId: 2
      },
      {
        id: 3,
        label: 'Partenaires',
        link: '/gestion/partenaires',
        parentId: 2
      },
      //{
      //   id: 4,
      //   label: 'Themes',
      //   link: '/gestion/theme',
      //   parentId: 2
      // },
      // {
      //   id: 5,
      //   label: 'Action climatique',
      //   link: '/gestion/action_climatique',
      //   parentId: 2
      // },
      // {
      //   id: 6,
      //   label: 'Document & Decision',
      //   link: '/gestion/document',
      //   parentId: 2
      // },
    ]
  },



];

